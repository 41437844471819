<template>
   
    <b-card-actions action-collapse title="Zone">  
        <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                    <b-col
          cols="12"
          class="mb-2"
        >
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                 id="profile"
                  ref="refPreviewEl"
                  :src="input.filename_pathurl"
                  height="110"
                  width="170"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>

                <small class="text-muted">(Recommend Size : 200x200)</small>
                <b-card-text class="my-50">
                  <b-link id="blog-image-text">
                    C:\fakepath\{{ input.userFile ? input.userFile.name : 'banner.jpg' }}
                  </b-link>
                </b-card-text>
                <div class="d-inline-block">
                  <b-form-file
                    ref="refInputEl"
                    v-model="input.userFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Choose file"
                    @input="inputImageRenderer"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>
                <b-col md="12">
                    <b-form-group>
                    <label>Name</label>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="name"
                    >
                          <b-form-input
                                v-model="input.name"
                                placeholder="Zone Name"
                            />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
                 

                  <b-col md="6">
                   <b-form-group>
                            <label>Prime</label>
                        <validation-provider
                                #default="{ errors }"
                            rules="required"
                            name="is_prime"
                            >
                            <b-form-checkbox
                            checked="true"
                            class="custom-control-primary"
                            v-model="input.is_prime"
                            switch
                        />
                                <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        
                    </b-form-group>  
                  
                </b-col>
                <b-col md="6">
                   <b-form-group>
                            <label>Active</label>
                        <validation-provider
                                #default="{ errors }"
                            rules="required"
                            name="is_active"
                            >
                            <b-form-checkbox
                            checked="true"
                            class="custom-control-primary"
                            v-model="input.is_active"
                            switch
                        />
                                <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        
                    </b-form-group>  
                  
                </b-col>
                
              
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-col>
                
              </b-row>
            </b-form>
        </validation-observer>
    </b-card-actions>
      

 
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormTextarea,BFormRating,BFormCheckbox,BFormCheckboxGroup
} from 'bootstrap-vue'
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink
} from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import BCardActions from '../../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'


//popup
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BCardActions,
    VueAutosuggest,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormRating,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BImg, BFormFile, BLink

  },
  directives: {
    Ripple,
  },
  data() {
    return {
        id:router.currentRoute.params.id,
        userData: JSON.parse(localStorage.getItem('userData')),
        input:{
         
          name: null,
          filename_pathurl:null,
        
          is_active:true,
          filename:null,
          is_prime:false
         
        }
        
        
     
    }
  },
  created() {
     
     if(this.id != undefined){
        
        this.getData();
      }
   
      
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
  methods: {
   
    async getData(){
       
      var zone = await axios.get('zone/v1/getdetail/'+this.id);
    console.log('zone',zone);
     if(zone){
         this.input = zone.data.data;
         this.input.is_active =  this.input.is_active===1?true:false;
          this.input.is_prime =  this.input.is_prime===1?true:false;

     }
     
    
      
    },
    
    
    makeToast(variant = null,title,message) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
            console.log('input',this.input);
            this.input.created_by = this.userData.id;
             this.input.base64 = '';
            if(this.input.userFile){
                this.input.base64 = document.getElementById('profile').src;
            }
            var data = JSON.stringify(this.input);
            if(this.id != undefined){
              axios.put('zone/v1/update/'+this.id,data)
              .then(res => { 
                console.log('res',res);
                if(!res.data.success){
                  this.makeToast('danger','Error 403',res.data.message);
                
                  
                }else{
                  this.$router.push('/zone/list', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Done`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully updated as .`,
                        },
                      })
                  });
                  

                }
              // this.actions = res.data.data; 
              }).catch(error => {
                this.makeToast('danger','Error 403',res.data.message);
                  console.log('error',error);
              })

            }else{
               axios.post('zone/v1/create',data)
              .then(res => { 
                console.log('res',res);
                if(!res.data.success){
                  this.makeToast('danger','Error 403',res.data.message);
                
                  
                }else{
                  this.$router.push('/zone/list', () => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Done`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully created.`,
                        },
                      })
                  });
                  

                }
            // this.actions = res.data.data; 
            }).catch(error => {
                this.makeToast('danger','Error 403',error);
                console.log('error',error);
            })

            }
          // eslint-disable-next-line
         
        }
      })
    },
    
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>